<template>
  <el-dialog
    v-dragDialog
    title="用户管理"
    :visible.sync="viewVisible"
    width="557px"
    :close-on-click-modal="false"
  >
    <el-input
      style="width:150px;margin-bottom:10px"
      placeholder="输入用户名搜索"
      size="mini"
      @change="filterPageData"
    ></el-input>
    <el-form style="width:557px" :inline="true">
      <el-form-item style="margin-bottom:10px">
        <el-table
          border
          height="300px"
          size="mini"
          :data="datas"
          highlight-current-row
          @current-change="currentChange"
          expand-on-click-node="false"
          style="width: 200px"
            :header-row-style="{'height':'30px;','background-color':'#4682B4','color':'#FFFFFF'}"
            :header-cell-style="{'height':'30px;','background-color':'#4682B4','color':'#FFFFFF'}"
          :show-header="true"
        >
          <el-table-column label="用户名称" prop="Name" min-width="100" align="left"></el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item>
          <div style="line-height:300px" >
           <el-button size="small" type="success" @click="insertUser">添加到角色</el-button>
           </div>
      </el-form-item>
      <el-form-item style="margin-bottom:10px;margin-right:0px">
        <el-table
          border
          height="300px"
          size="mini"
          :data="values"
          expand-on-click-node="false"
          style="width: 200px"
           :header-row-style="{'height':'30px;','background-color':'#4682B4','color':'#FFFFFF'}"
            :header-cell-style="{'height':'30px;','background-color':'#4682B4','color':'#FFFFFF'}"
          :show-header="true"
        >
          <el-table-column prop="Name" label="已包含用户" min-width="80"></el-table-column>
          <el-table-column label="操作" width="50">
            <template slot-scope="scope">
                <el-button type="text" size="small" @click="removeUser(scope.row)">移除</el-button>
            </template>
           </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="viewVisible = false">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { getAll,addMemberRole,deleteMemberRole } from "@/api/setting/member";
import { getRoleUsers } from "@/api/setting/role";

export default {
  data() {
    return {
      datas: [],
      values: [],
      viewVisible: false,
      roleKey: "",
      currentRow: ""
    };
  },
  methods: {
    show(roleKey) {
      this.roleKey = roleKey
      this.getData("")
      this.getValue()
      this.viewVisible = true
    },
    
    getData(search) {
       let para =  {
           search:search,
           memberType:"System",
       }
       getAll(para).then(response=>{
           if(response){
               this.datas = response.data
           }
       })
    },
  
    getValue() {
       let para = {
          roleKey: this.roleKey
       }
       getRoleUsers(para).then(response=>{
           if(response){
               this.values = response.data
           }
       })
    },

    
    currentChange(row) {
      this.currentRow = row
    },
 
    filterPageData(search) {
      this.getData(search);
    },

    insertUser(){
       if(!this.currentRow){
           this.errorMsg("请选则用户")
           return
       }
       let para = {
           roleKey : this.roleKey,
           userKey : this.currentRow.Key
       }
       addMemberRole(para).then(response=>{
           if(response){
               this.getValue()
           }
       })
    },

    removeUser(row){
       let para = {
           roleKey : this.roleKey,
           userKey : row.Key
       }
       deleteMemberRole(para).then(response=>{
           if(response){
               this.getValue()
           }
       })
    }
  },
  mounted() {}
};
</script>
<style scoped>
 .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
  color: #606266;
  line-height: 24px;
  font-size: 14px;
}
</style>


