<template>
  <el-dialog title="数据权限管理" :visible.sync="powerVisible" width="500px" :close-on-click-modal="false">
    <el-table border height="400px" size="mini" :data="rights" highlight-current-row expand-on-click-node="false" style="width: 100%">
      <el-table-column type="index"></el-table-column>
      <el-table-column label="乡镇或街道" prop="regionName" min-width="100" align="left"></el-table-column>
      <el-table-column label="授权访问" width="150" align="center">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.enabled" @change="rightChange(scope.row)"></el-switch>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button @click="powerVisible = false">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { GetDataRights, SetDataRights } from "@/api/setting/role";
export default {
  props: {
    showOption: {
      default: false,
    },
  },
  data () {
    return {
      rights: [],
      powerVisible: false,
      roleKey: "",
    };
  },
  methods: {
    //外部调用弹出，入口方法
    showManager (key) {
      this.rights = [];
      this.roleKey = key;
      this.powerVisible = true;
      this.getRightData("");
    },
    //加载左侧列表
    getRightData (search) {
      let para = {
        roleKey: this.roleKey,
        search: search,
      };
      GetDataRights(para).then((response) => {
        if (response) {
          this.rights = response.data;
        }
      });
    },
    rightChange (row) {
      let para = {
        roleKey: this.roleKey,
        regionKey: row.regionKey,
        enabled: row.enabled,
      };
      var msg = row.enabled ? "授权成功" : "已取消授权";
      SetDataRights(para).then((response) => {
        if (response) {
          this.msg.successMsg(msg);
        } else {
          row.enabled = !row.enabled;
        }
      });
    },
  },
  mounted () { },
};
</script>
<style>
</style>
<style lang="scss" scoped>
/deep/.el-dialog__body {
  padding-top: 0px;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
  color: #606266;
  line-height: 24px;
  font-size: 14px;
}
</style>