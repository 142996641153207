<template>
  <div class="roleinfo-container">
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
      <el-form :inline="true" :model="filters">
        <el-form-item>
          <el-input size="small" v-model="filters.search" @keyup.native.enter="getData" placeholder="名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="getData">查询</el-button>
           <el-button size="small" type="success" @click="create">新增</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <el-table    :header-cell-style="{
        'font-weight': '400',
        height: '40px',
        padding: '0',
        background: '#4a8bc1',
        color: 'white',
      }" stripe border size="mini" :data="datas" highlight-current-row expand-on-click-node="false" v-loading="listLoading" style="width: 100%;">
      <el-table-column prop="Name" label="角色名称" sortable min-width="130"></el-table-column>
      <el-table-column prop="Description" label="备注" sortable min-width="120"></el-table-column>
      <el-table-column sortable prop="InsertTime" label="创建时间" min-width="100" >
         <template slot-scope="scope">
          {{format.dateTimeFormat(scope.row.InsertTime)}}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300">
        <template slot-scope="scope">
          <el-button v-if="scope.row.Name!='administrators'" type="text" size="small" @click="update(scope.row)">修改</el-button>
          <el-button v-if="scope.row.Name!='administrators'" type="text" size="small" @click="remove(scope.$index, scope.row)">删除</el-button>
          <el-button v-if="scope.row.Name!='administrators'" type="text" size="small" @click="power(scope.$index, scope.row)">功能权限</el-button>
          <!-- <el-button v-if="scope.row.Name!='administrators'" type="text" size="small" @click="datapower(scope.$index, scope.row)">数据权限</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-sizes="pageSizesList" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" :current-page.sync="pageIndex"></el-pagination>
    </div>
    <div v-if="addFormVisible">
      <el-dialog v-dragDialog :title="textMap[dialogStatus]" :visible.sync="addFormVisible" width="450px" :close-on-click-modal="false" :modal-append-to-body="false">
        <el-form :model="addForm" ref="dataForm" label-width="130px" :rules="addFormRules">
          <el-form-item label="名称：" prop="Name">
            <el-col :span="19">
              <el-input v-model="addForm.Name" auto-complete="off" placeholder="请输入名称"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="备注：" prop="Description">
            <el-col :span="19">
              <el-input v-model="addForm.Description" type="textarea" auto-complete="off" placeholder="请输入备注"></el-input>
            </el-col>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addFormVisible = false">取消</el-button>
          <el-button v-if="dialogStatus=='create'" type="primary" @click="createSubmit">提交</el-button>
          <el-button v-else type="primary" @click="updateSubmit">提交</el-button>
        </div>
      </el-dialog>
    </div>
    <powerManager ref="powerManager"></powerManager>
    <roleUserView ref="userView"></roleUserView>
    <roleDataPower ref="roleDataPower"></roleDataPower>
  </div>
</template>
<script>
import { getPageList, save, del } from "@/api/setting/role";
import powerManager from "@/components/powerManager";
import roleUserView from "@/components/roleUserView";
import roleDataPower from "@/components/roleDataPower";
export default {
  
  components: {
    powerManager: powerManager,
    roleUserView: roleUserView,
    roleDataPower:roleDataPower,
  },
  data () {
    return {
      filters: {
        Name: ""
      },
      datas: [],
      total: 0,
      pageIndex: 1,
      pageSize: 10,
      pageSizesList: [10, 15, 20, 30, 50],
      listLoading: false,
      dialogStatus: "",
      addFormVisible: false,
      textMap: {
        update: "修改用户",
        create: "新增用户"
      },
      addForm: {
        Key: "",
        Name: "",
        Description: ""
      },
      addFormRules: {
        Name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      }
    };
  },
  methods: {
    create () {
      this.dialogStatus = "create";
      this.addForm = {
        Key: "",
        Name: "",
        Description: ""
      };
      this.addFormVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
      });
    },
    createSubmit: function () {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          let para = Object.assign({}, this.addForm);
          this.listLoading = true;
          save(para).then(response => {
            this.listLoading = false;
            if (response) {
              this.msg.successMsg("操作成功");
              this.addFormVisible = false;
              this.getData();
            }
          });
        }
      });
    },
    update (row) {
      this.addForm = Object.assign({}, row); // copy obj
      this.dialogStatus = "update";
      this.addFormVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    updateSubmit: function () {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          let data = Object.assign({}, this.addForm);
          this.listLoading = true;
          save(data).then(response => {
            this.listLoading = false;
            if (response) {
              this.msg.successMsg("操作成功");
              this.addFormVisible = false;
              this.getData();
            }
          });
        }
      });
    },
    remove: function (index, row) {
        this.$confirm("确认删除该角色吗?", "提示", {
        type: "warning",
      }).then(() => {
        let para = { roleKey: row.Key };
        del(para).then((response) => {
          if (response) {
            this.msg.successMsg("操作成功");
            this.getData();
          }
        });
      });
    },
    user (index, row) {
      this.$refs.userView.show(row.Key)
    },

    power (index, row) {
      this.$refs.powerManager.showManager(row.Key);
    },
    datapower(index,row)
    {
       this.$refs.roleDataPower.showManager(row.Key);
    },
    getData () {
      let data = {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        search: this.filters.search
      };
      this.listLoading = true;
      getPageList(data).then(response => {
        this.listLoading = false;
        if (response) {
          this.datas = response.Items;
          this.total = response.Total||response.Items.length;
        }
      });
    },

    handleSizeChange (val) {
      var _this = this;
      var pageSize = `${val}`;
      this.pageIndex = 1;
      this.pageSize = parseInt(pageSize);
      this.$nextTick(() => this.getData());
    },

    handleCurrentChange (val) {
      this.pageIndex = `${val}`;
      this.getData();
    }
  },
  mounted () {
    this.getData();
  }
};
</script>
<style lang='scss' scoped>
.roleinfo-container {
  padding: 10px;
}
/deep/.el-dialog {
  margin-top: 10px !important;
}
</style>

